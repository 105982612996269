import { IFiltroMovimentoRequest } from "./../../models/IFiltroMovimentoRequest";
import HttpClient from "../../helpers/axios";

class DocumentoService {
  GetDocumentosFiscais(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v2/Documentos/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`
    );
  }

  GetDocumentosIcms(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v2/DocumentosIcms/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`
    );
  }

  GetDocumentosIcmsSt(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v2/DocumentosIcmsST/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`
    );
  }

  GetDocumentosIpi(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v2/DocumentosIPI/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`
    );
  }

  GetDocumentosPisCofins(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v2/DocumentosPisCofins/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`
    )
  }

  GetDocumentosProdutos(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v2/DocumentosProdutos/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`
    )
  }

  GetDocumentosCfop(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v2/DocumentosCFOP/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`)
  }

  GetDocumentosCstIcms(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v2/DocumentosCSTIcms/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`)
  }

  GetDocumentosCstIpi(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v2/DocumentosCSTIpi/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`)
  }

  GetDocumentosCstPisCofins(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v2/DocumentosCSTPisCofins/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`)
  }

  GetDocumentosCancelados(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v2/DocumentosCancelados/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`)
  }

  GetDocumentosInutilizados(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v1/DocumentosInutilizados/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}`)
  }

  GetDocumentosFaltantes(
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v1/DocumentosFaltantes/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}`)
  }

  GetDocumentosNcmCstIcms(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v1/DocumentoNCMCSTIcms/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`)
  }

  GetDocumentosNcmCstIpi(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v1/DocumentoNCMCSTIpi/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`)
  }

  GetDocumentosNcmCstPisCofins(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v1/DocumentoNCMCSTPisCofins/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`)
  }

  GetDownload(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(`/api/v1/Documentos/Download/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`)
  }

  GetArquivosGerais(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v1/Arquivos/${contabilistaId}/${cnpjCpf}/${filtro.Ano}/${filtro.Mes}`)
  }

  GetMdfe(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v1/MDFe/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}`)
  }

  GetPreviewDanfe(
    contabilistaId: number,
    cnpjCpf: number,
    chaveAcesso: number
  ) {
    return HttpClient.get(
      `/api/v1/NFe/Danfe/${contabilistaId}/${cnpjCpf}/${chaveAcesso}`)
  }

  GetPreviewDanfeCte(
    contabilistaId: number,
    cnpjCpf: number,
    chaveAcesso: number
  ) {
    return HttpClient.get(
      `/api/v1/NFe/DanfeCe/${contabilistaId}/${cnpjCpf}/${chaveAcesso}`)
  }

  GetMonofasicoByNcm(ncm: string) {
    return HttpClient.get(`/api/v1/monofasico/por-ncm/${ncm}`)
  }

  GetDadosRecebimentoXml() {
    return HttpClient.get('/api/v1/DadosRecebimentoXml');
  }

  GetListaRecebimentoXml(cnpj?: string, mes?: number, ano?: number) {
    return cnpj !== undefined
      ? HttpClient.get(`/api/v1/ListaRecebimentoXml/${cnpj}/${mes}/${ano}`)
      : HttpClient.get('/api/v1/ListaRecebimentoXml')
  }

  GetListaRecebimentoXmlProcessado(cnpj?: string, mes?: number, ano?: number) {
    return cnpj !== undefined
      ? HttpClient.get(`/api/v1/ListaRecebimentoXmlProcessados/${cnpj}/${mes}/${ano}`)
      : HttpClient.get('/api/v1/ListaRecebimentoXmlProcessados')
  }

  GetListaRecebimentoXmlNaoProcessado(cnpj?: string, mes?: number, ano?: number) {
    return cnpj !== undefined
      ? HttpClient.get(`/api/v1/ListaRecebimentoXmlNaoProcessados/${cnpj}/${mes}/${ano}`)
      : HttpClient.get('/api/v1/ListaRecebimentoXmlNaoProcessados')
  }

  GetListaRecebimentoXmlSemErro(cnpj?: string, mes?: number, ano?: number) {
    return cnpj !== undefined
      ? HttpClient.get(`/api/v1/ListaRecebimentoXmlProcessadosSemErro/${cnpj}/${mes}/${ano}`)
      : HttpClient.get('/api/v1/ListaRecebimentoXmlProcessadosSemErro')
  }

  GetListaRecebimentoXmlComErro(cnpj?: string, mes?: number, ano?: number) {
    return cnpj !== undefined
      ? HttpClient.get(`/api/v1/ListaRecebimentoXmlProcessadosComErro/${cnpj}/${mes}/${ano}`)
      : HttpClient.get('/api/v1/ListaRecebimentoXmlProcessadosComErro')
  }

  GetListaRecebimentoXmlAguardando(cnpj?: string, mes?: number, ano?: number) {
    return cnpj !== undefined
      ? HttpClient.get(`/api/v1/ListaRecebimentoXmlProcessadosAguardando/${cnpj}/${mes}/${ano}`)
      : HttpClient.get('/api/v1/ListaRecebimentoXmlProcessadosAguardando')
  }

  GetDocumentosCte(
    contabilistaId: number,
    cnpjCpf: number,
    filtro: IFiltroMovimentoRequest
  ) {
    return HttpClient.get(
      `/api/v1/CTe/${contabilistaId}/${cnpjCpf}/${filtro.Mes}/${filtro.Ano}/${filtro.Modelo}/${filtro.Tipo}`)
  }
}
export default new DocumentoService();
