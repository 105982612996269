import { useToast } from 'vue-toastification';
import { IContabilistaRequest } from '../../../models/IContabilistaRequest';
import { authStore } from './../auth/index';
import ContabilistaService from '../../../services/contabilistas/ContabilistaService';
import { defineStore } from 'pinia';
import eventBus from '../../../helpers/event-bus'

const emitPopup = eventBus.usePopup()
const emitDataSource = eventBus.useDataSource()
const toast = useToast()

export const contabilistaStore = defineStore({
  id: 'contabilistaStore',
  state: () => ({
    auth: authStore(),
    contabilista: {} as IContabilistaRequest
  }),
  actions: {
    SUBMIT_FORM(payload: IContabilistaRequest){
      payload.id === 0 ? this.NEW_CONTABILISTA(payload) : this.UPDATE_CONTABILISTA(payload)
    },

    async LOAD(){
      try {
        const response = await ContabilistaService.GetContabilistasByUsuarioEmail(this.auth.currentUser.email)
        if(response.status === 200) return response.data
      } catch (error: any) {
        toast.error(error.response.data)
      }
    },

    async NEW_CONTABILISTA(payload: IContabilistaRequest){
      try {
        const response = await ContabilistaService.NewContabilista(payload)
        if(response.status === 200){
          emitPopup.close();
          toast.success('Contabilista cadastrado com sucesso!');
          emitDataSource.reload()
        }
      } catch (error: any) {
        toast.error(error.response.data)
      }
    },

    async UPDATE_CONTABILISTA(payload: IContabilistaRequest){
      try {
        const response = await ContabilistaService.UpdateContabilista(payload);
        if(response.status === 200){
          emitPopup.close();
          toast.success('Contabilista atualizado com sucesso!');
          emitDataSource.reload()
        }
      } catch (error: any) {
        toast.error(error.response.data)
      }
    },

    async DELETE_CONTABILISTA(payload: number){
      try {
        const response = await ContabilistaService.DeleteContabilista(payload)
        if(response.status === 200){
          toast.success('Contabilista apagado com sucesso!');
          emitDataSource.reload();
        }
      } catch (error: any) {
        toast.error(error.response.data)
      }
    },

    async GET_CONTABILISTA_BY_ID(payload: number){
      try {
        const response = await ContabilistaService.GetContabilistaById(payload)
        if(response.status === 200){
          this.contabilista = response.data
          toast.info('Contabilista encontrado com sucesso!');
        }
      } catch (error: any) {
        toast.error(error.response.data)
      }
    }
  }
})