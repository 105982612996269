import { useToast } from 'vue-toastification';
import { IClienteRequest } from './../../../models/IClienteRequest';
import { defineStore } from 'pinia';
import ClienteService from '../../../services/clientes/ClienteService';
import eventBus from '../../../helpers/event-bus';

const emitPopup = eventBus.usePopup();
const emitDataSource = eventBus.useDataSource();
const toast = useToast()

export const clienteStore = defineStore({
  id: 'clienteStore',
  state: () => ({
    clienteSelected: {} as IClienteRequest,
    cliente: {} as IClienteRequest
  }),
  actions: {
    SET_CLIENTE_FOR_MOVIMENTS(payload: any) {
      this.clienteSelected = payload;
      sessionStorage.setItem('client_selected', JSON.stringify(this.clienteSelected))
    },
    SUBMIT_FORM(payload: IClienteRequest) {
      payload.id === 0 ? this.NEW_CLIENTE(payload) : this.UPDATE_CLIENTE(payload)
    },

    async LOAD(payloadContabilistaId: number, payloadTipo: number) {
      try {
        const response = await ClienteService.GetAllClientesByType(payloadContabilistaId, payloadTipo);
        if (response.status === 200) return response.data
      } catch (error: any) {
        if(error.response){
          toast.error(error.response.data)
        }
      }
    },

    async LOAD_CLIENTES_BY_CONTABILISTA_ID(payload: number){
      try {
        const response = await ClienteService.GetClientesByContabilistaId(payload)
        if(response.status === 200) return response.data
      } catch (error: any) {
        toast.error(error.response.data)
      }
    },

    async NEW_CLIENTE(payload: IClienteRequest) {
      try {
        const response = await ClienteService.NewCliente(payload);
        if (response.status === 200) {
          emitPopup.close();
          toast.success('Cliente cadastrado com sucesso!');
          emitDataSource.reload();
        }
      } catch (error: any) {
        toast.error(error.response.data)
      }
    },

    async UPDATE_CLIENTE(payload: IClienteRequest) {
      try {
        const response = await ClienteService.UpdateCliente(payload);
        if (response.status === 200) {
          emitPopup.close();
          toast.success('Cliente atualizado com sucesso!');
          emitDataSource.reload()
        }
      } catch (error: any) {
        toast.error(error.response.data)
      }
    },

    async DELETE_CLIENTE(payload: number) {
      try {
        const response = await ClienteService.DeleteCliente(payload);
        if (response.status === 200) {
          toast.success('Cliente apagado com sucesso!');
          emitDataSource.reload()
        }
      } catch (error: any) {
        toast.error(error.response.data)
      }
    },

    async GET_CLIENTE_BY_ID(payload: number) {
      try {
        const response = await ClienteService.GetClienteById(payload);
        if (response.status === 200) {
          toast.info('Cliente encontrado com sucesso!');
          this.cliente = response.data
        }
      } catch (error: any) {
        toast.error(error.response.data)
      }
    }
  }
})