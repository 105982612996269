import { IContabilistaRequest } from '../../models/IContabilistaRequest';
import HttpClient from '../../helpers/axios';
class ContabilistaService {
  GetContabilistas() {
    return HttpClient.get('/api/v1/contabilistas/ativos');
  }

  GetContabilistasByUsuarioEmail(usuarioEmail: string){
    return HttpClient.get(`/api/v1/contabilistas/por-usuario/${usuarioEmail}`)
  }

  GetContabilistaById(contabilistaId: number) {
    return HttpClient.get(`/api/v1/contabilistas/${contabilistaId}`)
  }

  NewContabilista(contabilista: IContabilistaRequest) {
    return HttpClient.post('/api/v1/contabilistas', contabilista)
  }

  UpdateContabilista(contabilista: IContabilistaRequest) {
    return HttpClient.put('/api/v1/contabilistas', contabilista)
  }

  DeleteContabilista(contabilistaId: number) {
    return HttpClient.delete(`api/v1/contabilistas/${contabilistaId}`)
  }
}

export default new ContabilistaService();