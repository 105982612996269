<template>
  <div>
    <p class="title">Contabilistas</p>
    <hr />
    <DataGridFactory
      :dataSource="dataSource"
      :AddButtonFunction="AddButton"
      :EditButtonFunction="EditButton"
    >
      <template v-slot:button>
        <DxButton
          hint="Visualizar Clientes deste Contabilista"
          icon="mdi mdi-account-search mdi-18px"
          :on-click="GetClientesByContabilistaRedirect"
        ></DxButton>
      </template>

      <template v-slot:column>
        <DxColumn
          alignment="left"
          data-field="nomeFantasia"
          sort="asc"
          caption="Nome"
        />

        <DxColumn alignment="center" data-field="email" />

        <DxColumn
          alignment="right"
          data-field="telefone"
          :customize-text="(cell) => {
            return Validators.formataNumeroTelefone(cell.value)
          }"
        />

        <DxColumn data-field="ativo" />
      </template>
    </DataGridFactory>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>
