import { defineComponent, onBeforeUnmount, onMounted } from 'vue';
import { DxColumn, DxButton } from 'devextreme-vue/ui/data-grid';
import DataGridFactory from '../../components/datagrid-factory/index.vue'
import Validators  from '../../helpers/validators-input';
import eventBus from '../../helpers/event-bus'
import DataSource from 'devextreme/data/data_source';
import { contabilistaStore } from '../../store/modules/contabilistas';
import router from '../../router';

export default defineComponent({
  name: 'Contabilistas',
  setup() {
    onMounted(() => {
      dataSourceEvent.listen(ReloadDataSource)
    })

    onBeforeUnmount(() => {
      dataSourceEvent.off(ReloadDataSource)
    })
    
    const popupEvent = eventBus.usePopup()
    const dataSourceEvent = eventBus.useDataSource();
    const store = contabilistaStore()
    const dataSource = new DataSource({
      key: 'id',
      load: () => store.LOAD()
    })
    
    async function AddButton(){
      popupEvent.open({
        component: 'FormContabilista',
        title: 'Adicionar Novo Contabilista',
        props: {
          id: 0,
          razaoSocial: '',
          nomeFantasia: '',
          email: '',
          senhaUsuario: '',
          cnpjCpf: null,
          telefone: '',
          ativo: true,
          isReadonly: false
        },
        size: { width: 600, height: 440 }
      })
    }

    async function EditButton(e: any){
      store.$reset()
      const rowKey = e.row.key;
      if(rowKey) await store.GET_CONTABILISTA_BY_ID(rowKey)
      await popupEvent.open({
        component: 'FormContabilista',
        title: `Dados do Contabilista: ${store.contabilista.nomeFantasia}`,
        props: Object.assign(store.contabilista, {isReadonly: true}), 
        size: {width: 600, height: 350}
      })
    }

    
    async function GetClientesByContabilistaRedirect(e: any){
      const rowKey: number = e.row.key
      router.push({name: 'Clientes', params: {id: rowKey}})
    }

    function ReloadDataSource() {
      dataSource.reload()
    }

    return {
      GetClientesByContabilistaRedirect,
      AddButton,
      EditButton,
      dataSource,
      Validators
    }
  },
  components: {
    DataGridFactory,
    DxColumn,
    DxButton
  }
})