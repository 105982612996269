import { useToast } from 'vue-toastification';
import { clienteStore } from './../clientes/index';
import { authStore } from '../../../store/modules/auth';
import { IFiltroMovimentoRequest } from './../../../models/IFiltroMovimentoRequest';
import { defineStore } from 'pinia';
import DocumentoService from '../../../services/documentos'

const toast = useToast()

export const documentoStore = defineStore({
  id: 'documentoStore',
  state: () => ({
    filter: {} as IFiltroMovimentoRequest,
    DocFiscais: {
      table: {},
      valorTotal: 0
    },
    DocIcms: {
      table: {},
      totalBcIcms: 0,
      totalDocs: 0,
      totalFcp: 0,
      totalFcpUfDest: 0,
      totalIcms: 0,
      totalIcmsDeson: 0,
      totalIcmsUfDest: 0,
      totalIcmsUfRemet: 0,
    },
    DocIcmsSt: {
      table: {},
      totalBcSt: 0,
      totalDocs: 0,
      totalFcpSt: 0,
      totalFcpStRet: 0,
      totalIcmsSt: 0
    },
    DocIpi: {
      table: {},
      totalDocs: 0,
      totalII: 0,
      totalIpi: 0,
      totalIpiDevol: 0
    },
    DocPisCofins: {
      table: {},
      totalCofins: 0,
      totalDocs: 0,
      totalPis: 0,
    },
    DocProdutos: {
      table: {},
      valorTotal: 0
    },
    DocCfop: {
      table: {},
      totalDescontos: 0,
      totalFcpSt: 0,
      totalFrete: 0,
      totalIcmsDeson: 0,
      totalIcmsSt: 0,
      totalIpi: 0,
      totalOutros: 0,
      totalProdutos: 0,
      totalSeguro: 0,
      valorTotal: 0
    },
    DocCstIcms: {
      table: {},
      totalDescontos: 0,
      totalFcpSt: 0,
      totalFrete: 0,
      totalIcmsDeson: 0,
      totalIcmsSt: 0,
      totalIpi: 0,
      totalOutros: 0,
      totalProdutos: 0,
      totalSeguro: 0,
      valorTotal: 0
    },
    DocCstIpi: {
      table: {},
      totalFrete: 0,
      totalIpi: 0,
      totalOutros: 0,
      totalProdutos: 0,
      totalSeguro: 0,
      valorIpiDevol: 0
    },
    DocCstPisCofins: {
      table: {},
      totalBcCofins: 0,
      totalBcPis: 0,
      totalCofins: 0,
      totalDesc: 0,
      totalFrete: 0,
      totalOutros: 0,
      totalPis: 0,
      totalProdutos: 0,
      totalSeguro: 0
    },
    DocCancelados: {
      table: {},
      valorTotal: 0
    },
    DocInutilizados: {},
    DocFaltantes: {},
    DocNcmCstIcms: {
      table: {},
      valorTotal: 0
    },
    DocNcmCstIpi: {
      table: {},
      valorTotal: 0
    },
    DocNcmCstPisCofins: {
      table: {},
      valorTotal: 0
    },
    DocArquivosGerais: {},
    DocMdfe: {
      table: {}
    },
    DocCte: {
      table: {}
    },
    DocMonofasico: {
      ncm: null,
      cstFabImp: null,
      cstAtacado: null,
      cstVarejo: null,
      observacoes: '',
      monofasicoSt: false,
      aliquotaZero: false,
      inicioVigencia: '',
      finalVigencia: '',
      grupoBeneficiado: '',
      origem: '',
      descricao1Ex: '',
      descricao2Ex: '',
      descricao3Ex: '',
      aliquotaPisFabricante: '',
      aliquotaCofinsFabricante: '',
      aliquotaPisAtacado: '',
      aliquotaCofinsAtacado: '',
      aliquotaPisVarejo: '',
      aliquotaCofinsVarejo: '',
      codigoEfd: null,
      baseLegal: ''
    },
    DocListaRecebimentoXml: {},
    DocListaRecebimentoXmlNaoProcessado: {},
    DocListaRecebimentoXmlProcessado: {},
    DocListaRecebimentoXmlSemErro: {},
    DocListaRecebimentoXmlComErro: {},
    DocListaRecebimentoXmlAguardando: {},
  }),
  actions: {
    async DOC_FISCAIS() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosFiscais(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200 && response.data.table.length > 0) {
          this.DocFiscais = response.data
        } else {
          toast.info('Sem documentos fiscais a exibir')
        }
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_ICMS() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosIcms(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocIcms = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_ICMS_ST() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosIcmsSt(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocIcmsSt = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_IPI() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosIpi(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocIpi = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_PIS_COFINS() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosPisCofins(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocPisCofins = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_PRODUTOS() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosProdutos(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocProdutos = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_CFOP() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosCfop(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocCfop = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_CST_ICMS() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosCstIcms(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocCstIcms = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_CST_IPI() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosCstIpi(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocCstIpi = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_CST_PIS_COFINS() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosCstPisCofins(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocCstPisCofins = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_CANCELADOS() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosCancelados(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocCancelados = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_INUTILIZADOS() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosInutilizados(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocInutilizados = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_FALTANTES() { 
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosFaltantes(cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocFaltantes = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_NCM_CST_ICMS() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosNcmCstIcms(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocNcmCstIcms = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_NCM_CST_IPI() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosNcmCstIpi(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocNcmCstIpi = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_NCM_CST_PIS_COFINS() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosNcmCstPisCofins(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocNcmCstPisCofins = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_ARQUIVOS_GERAIS() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetArquivosGerais(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocArquivosGerais = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async DOC_MDFE() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetMdfe(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status === 200) this.DocMdfe = response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async GET_PREVIEW_DANFE(chaveAcesso: number) {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetPreviewDanfe(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, chaveAcesso)
        if (response.status === 200) return response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async GET_PREVIEW_DANFE_CTE(chaveAcesso: number) {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetPreviewDanfeCte(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, chaveAcesso)
        if (response.status === 200) return response.data
      } catch (error: any) {
        toast.error(error.response)
      }
    },
    async GET_DOWNLOAD_DOCUMENTOS() {
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDownload(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if (response.status && response.status === 200) return response.data
      } catch (error: any) {
        toast.error(error)
      }
    },
    async DOC_CTE(){
      const auth = authStore();
      const cliente = clienteStore();
      try {
        const response = await DocumentoService.GetDocumentosCte(auth.getContabilistaId, cliente.clienteSelected.cnpjCpf, this.filter)
        if(response.status === 200) this.DocCte = response.data;
      } catch (error: any) {
        toast.error(error)
      }
    },
    async GET_MONOFASICO_BY_NCM(ncm: string) {
      try {
        const response = await DocumentoService.GetMonofasicoByNcm(ncm);
        if (response.status === 200) {
          this.DocMonofasico = response.data;
        } else if (response.status === 204) {
          toast.error('NCM não encontrado!')
        }
      } catch (error: any) {
        toast.error(error)
      }
    },
    async GET_DADOS_RECEBIMENTO_XML(){
      try {
        const response = await DocumentoService.GetDadosRecebimentoXml();
        if(response.status === 200) return response.data;
      } catch (error:any) {
        toast.error(error);
      }
    },
    async GET_LISTA_RECEBIMENTO_XML(cnpj?: string, mes?:number, ano?:number){
      try {
        const response = await DocumentoService.GetListaRecebimentoXml(cnpj, mes, ano);
        if(response.status === 200) this.DocListaRecebimentoXml = response.data;
      } catch (error: any) {
        toast.error(error)
      }
    },
    async GET_LISTA_RECEBIMENTO_XML_NAO_PROCESSADO(cnpj?: string, mes?:number, ano?:number){
      try {
        const response = await DocumentoService.GetListaRecebimentoXmlNaoProcessado(cnpj, mes, ano);
        if(response.status === 200) this.DocListaRecebimentoXmlNaoProcessado = response.data
      } catch (error: any) {
        toast.error(error)
      }
    },
    async GET_LISTA_RECEBIMENTO_XML_PROCESSADO(cnpj?: string, mes?:number, ano?:number){
      try {
        const response = await DocumentoService.GetListaRecebimentoXmlProcessado(cnpj, mes, ano);
        if(response.status === 200) this.DocListaRecebimentoXmlProcessado = response.data
      } catch (error: any) {
        toast.error(error)
      }
    },
    async GET_LISTA_RECEBIMENTO_XML_SEM_ERRO(cnpj?: string, mes?:number, ano?:number){
      try {
        const response = await DocumentoService.GetListaRecebimentoXmlSemErro(cnpj, mes, ano);
        if(response.status === 200) this.DocListaRecebimentoXmlSemErro = response.data
      } catch (error: any) {
        toast.error(error)
      }
    },
    async GET_LISTA_RECEBIMENTO_XML_COM_ERRO(cnpj?: string, mes?:number, ano?:number){
      try {
        const response = await DocumentoService.GetListaRecebimentoXmlComErro(cnpj, mes, ano);
        if(response.status === 200) this.DocListaRecebimentoXmlComErro = response.data
      } catch (error: any) {
        toast.error(error)
      }
    },
    async GET_LISTA_RECEBIMENTO_XML_AGUARDANDO(cnpj?: string, mes?:number, ano?:number){
      try {
        const response = await DocumentoService.GetListaRecebimentoXmlAguardando(cnpj, mes, ano);
        if(response.status === 200) this.DocListaRecebimentoXmlAguardando = response.data
      } catch (error: any) {
        toast.error(error)
      }
    },
  }
})