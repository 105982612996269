import { IClienteRequest } from './../../models/IClienteRequest';
import HttpClient from "../../helpers/axios";
class ClienteService {
  GetClientes() {
    return HttpClient.get('/api/v1/clientes');
  }

  GetClientesByContabilistaId(contabilistaId: number) {
    return HttpClient.get(`/api/v1/clientes/por-contador/${contabilistaId}`)
  }

  GetAllClientesByType(contabilistaId: number, userType: number) {
    return HttpClient.get(`/api/v1/clientes/por-contador/${contabilistaId}/${userType}`)
  }

  GetClienteById(clienteId: number) {
    return HttpClient.get(`/api/v1/clientes/${clienteId}`)
  }

  NewCliente(cliente: IClienteRequest) {
    return HttpClient.post('/api/v1/clientes', cliente)
  }

  UpdateCliente(cliente: IClienteRequest) {
    return HttpClient.put('/api/v1/clientes', cliente)
  }

  DeleteCliente(clienteId: number) {
    return HttpClient.delete(`/api/v1/clientes/${clienteId}`)
  }
}

export default new ClienteService();